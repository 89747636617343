/* latin-ext */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/audiowide-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/audiowide-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


.hover-underline-animation-light {
  display: inline-block;
  position: relative;
  color: #dfdfe3;
}

.hover-underline-animation-light ::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #dfdfe3;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation-light :hover::after {
  transform: scaleX(1);
  transform-origin: center;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #211a52;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #211a52;
  transform-origin: center;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: center;
}


/* css */
html{
  background: url("assets/background.png") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #f7fafc;
  height: 100%!important;
  scroll-padding-top: 100px;
}
body{
  background: #00000000!important;
}

.App {
  font-family: Roboto, sans-serif;
  background-color: #dfdfe300;
  /* height: 100%; */
}
#root {
  /* height: 100%; */
}

/* .container {
  padding: 16px 16px 16px 16px;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  box-shadow:rgb(0 0 0 / 2%) 0px 3.5px 5.5px;
} */

.container-header {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.container-header-text {
  line-height: 2.5;
  font-weight: bold;
}

.metric-title {
  font-weight: bold;
  font-size: 1.5em;
}

.metric-value {
  font-size: 1.3em;
}

.agent-table-item:hover{
  background-color: #211a52;
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  height: 7px;
	width: 7px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #211a52;
}


/* Solves table */
.solve-table-container {
  caret-color: transparent;
}
.solve-table th, td {
  border-bottom: 1px solid #ddd;
  border-color: #ddd;
  bottom: 0;
  height: 57.894px;
}

.rotated-header th {
  height: 100px;
  vertical-align: bottom;
  line-height: 1;
  border-bottom: 1px solid #ddd;
}

.rotated-header-container {
  width: 15px;
}

.rotated-header-content {
  transform-origin: bottom left;
  transform: translateX(10px) rotate(-20deg);
}

.table-solve-icon-container {
  padding: 0 0 0 0!important;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.challenges-page {
  max-width: 1140px;
  width: 100%;
}

.challenge-container {
  max-width: 260px;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .challenges-page {
    max-width: 990px;
  }
  .challenge-container {
    max-width: 303px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .challenges-page {
    max-width: 720px;
  }
  .challenge-container {
    max-width: 330px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .challenges-page {
    max-width: 540px;
  }
  .challenge-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .challenges-page {
    max-width: 540px;
  }
  .challenge-container {
    max-width: 100%;
  }
}